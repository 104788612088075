<template>
  <div class="mx-auto max-w-[1400px]">
    <StoryblokComponent
      v-for="stageBlok in stageBlocks"
      :key="stageBlok._uid"
      :blok="stageBlok"
      :preload-media="true"
      critical
      class="mx-auto"
    />

    <KeepAlive>
      <div>
        <div
          v-for="(blok, i) in contentBlocks"
          :key="blok._uid"
          class="mx-auto"
        >
          <AsyncStoryblokComponent
            :blok="blok as SbBlokData"
            class="mx-auto"
            :preload-media="false"
            :critical="!i"
          />
        </div>
      </div>
    </KeepAlive>
  </div>
</template>

<script setup lang="ts">
import type { SbBlokData } from '@storyblok/vue'
import { useCmsStory } from '~/composables/useCmsStory'
import { getCmsMeta } from '~/utils/cms'

const { data } = await useCmsStory({
  params: { slug: '/' },
})

const contentBlocks = computed(() => data.value?.content?.content ?? [])
const stageBlocks = computed(() => data.value?.content?.stage ?? [])
const cmsMeta = computed(() => getCmsMeta(data.value?.content))

const $currentShop = useCurrentShop()
const route = useRoute()

useSeoMeta(cmsMeta.value)
useHead({
  link: [
    {
      rel: 'canonical',
      key: 'canonical',
      href: sanitizeCanonical(getUrl($currentShop.value.domain, route?.path)),
    },
  ],
})
useJsonld(() => {
  const url: string = `${getProtocol()}${$currentShop.value.domain}`
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Fielmann',
    url,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${url}/search/?term={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }
})

defineOptions({ name: 'HomePage' })
definePageMeta({ pageType: 'homepage' })
</script>
